import React, {useEffect, useRef, useState} from "react";
import {MdOutlineHourglassTop} from "react-icons/md";
import {TimeRemaining} from "@/components/TimeRemaining";
import {useQuery} from "@tanstack/react-query";
import {fetchUserBlastPointsStats, fetchUserStats} from "@/api/perpsDataFetcher";
import {useCurrentUser} from "@/hooks/useCurrentUser";
import {AddressZero} from "@ethersproject/constants";
import {prettifyNumber} from "@/util/converters";
import {Tooltip as ReactTooltip} from "react-tooltip";
import classNames from "classnames";
import {useRouter} from "next/router";

export const BlastPointsView = () => {
  const {address} = useCurrentUser();
  const router = useRouter();
  const [lastTotalPoints, setLastTotalPoints] = useState(0);
  const [pendingAmountApprox, setPendingAmountApprox] = useState(0);

  const query = useQuery({
    queryKey: ["blast_point_stats", address],
    queryFn: async () => await fetchUserBlastPointsStats(address || AddressZero),
    gcTime: 20 * 1000, // 20 seconds
    refetchInterval: 5 * 1000, // 5 seconds
  });

  useEffect(() => {
    if (query.data) {
      setTimeout(() => {
        setPendingAmountApprox(0);
        setLastTotalPoints(query.data.outstandingPoints + query.data.sentPoints);
      }, 2000)
    }
  }, [query.data]);

  const getApproximatePendingAmount = () => {
    const duration = new Date().getTime() - query.dataUpdatedAt;
    return (query.data?.pointsPerMs || 0) * duration / 5;
  }

  useEffect(() => {
    const interval = setInterval(() => setPendingAmountApprox(getApproximatePendingAmount()), 100);
    return () => clearInterval(interval);
  }, [query.data?.pointsPerMs]);
  
  const {isLoading, data: stats, isError} = useQuery({
    queryKey: ["user-stats", address],
    queryFn: async () => await fetchUserStats(address!),
    enabled: !!address && router.isReady,
    refetchInterval: 60 * 1000 // 1 minute
  });

  const getTotalGold = () => {
    const blastGold = (stats?.leaderboardItem?.blastGold || 0) + (stats?.leaderboardItem?.claimableBlastGold || 0);
    return prettifyNumber(blastGold);
  }

  const renderSkeletonLoading = () => (
    <div className="min-w-[300px] min-h-[100px] bg-slate-600 animate-pulse"/>
  );

  const BlastPointsTooltip = () => {
    return (
      <>
        {
          !query.isLoading && !isLoading ?
            <div className="standard-stack min-w-[350px] !gap-4 border rounded-md p-4 overflow-clip border-camo-500 transition-colors bg-gradient-to-b from-[#11140C] from-[27.54%] to-[#252B1B]">
              <div className="flex flex-col col-auto items-center gap-4">
                <div className="flex flex-col items-start">
                  <span className="text-neutral-content text-xs">GOLD RECEIVED</span>
                  <div className="flex items-center gap-2">
                    <img src="/static/blast_gold.png" alt="gold" className="w-6 h-6 rounded-full"/>
                    <p className="text-white text-2xl">{getTotalGold()}</p>
                  </div>
                </div>
                <div className="flex flex-row gap-2 w-full justify-between">
                  <div className="flex flex-col items-start">
                    <span className="text-neutral-content text-xs">PTS RECEIVED</span>
                    <span className="text-3xl glow-text text-white">{prettifyNumber(query.data?.sentPoints || 0)}</span>
                  </div>
                  <div className="flex flex-col items-end">
                    <span className="text-neutral-content text-xs">PTS PENDING</span>
                    <span className="text-3xl glow-text text-white">
                      {((query.data?.outstandingPoints || 0) + pendingAmountApprox).toLocaleString([], {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                    </span>
                </div>
              </div>
              </div>
              <div className="flex flex-col">
                <div className="w-full flex flex-col gap-1 items-start text-xs">
                  <span className="text-neutral-content">Pending points will be sent in:</span>
                  <span className="flex flex-row items-start gap-1">
                                      <MdOutlineHourglassTop/>
                                      <TimeRemaining epochSeconds={getNextDistribution()}/>
                                  </span>
                </div>
              </div>
            </div> :
            renderSkeletonLoading()
        }
      </>
    );
  }

  const getTotalPoints = () => {
    if (!query.data) {
      return 0;
    }
    return prettifyNumber(query.data.outstandingPoints + query.data.sentPoints);
  }

  return (
    <>
      <div id="blast_points"
           className={classNames("flex items-center h-[40px] md:h-[50px] px-2 md:px-4 hover:bg-neutral-content/20 hover:cursor-pointer", {
             "bg-[#FCFC05] text-base-100 text-base italic fadeLeft": query.data && lastTotalPoints > 0 && lastTotalPoints !== (query.data.outstandingPoints + query.data.sentPoints)
           })}>
        {
          (query.data && lastTotalPoints > 0 && lastTotalPoints !== (query.data.outstandingPoints + query.data.sentPoints)) ?
            <span>
                        +{(query.data.outstandingPoints + query.data.sentPoints - lastTotalPoints).toFixed(1)} PTS
                    </span> :
            <div className="flex flex-row items-end justify-between cursor-pointer gap-2">
              <span className="italic text-base text-[#FCFC05]">{getTotalPoints()}</span>
              <img src="/static/blast.svg" className="h-6 w-6" alt="blast_earning"/>
            </div>
        }
      </div>
      <ReactTooltip
        anchorSelect="#blast_points"
        id="tooltip_blast_points"
        place={"bottom"}
        offset={0}
        className="z-50"
        style={{
          opacity: 1,
          backgroundColor: "unset"
        }}
      >
        <BlastPointsTooltip/>
      </ReactTooltip>
    </>
  );
}
const getNextDistribution = () => {
  let start = 1705536000;
  const now = Math.floor(Date.now() / 1000);
  while (start < now) {
    start += 3600; // 1 hour
  }
  return start;
}
